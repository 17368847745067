import { Component } from './component'
import { Action } from './action'

declare global {
	interface Window {
		trackCookies: {
			allowed: {
				tech: boolean
				analytic: boolean
				preference: boolean
				marketing: boolean
			}
			used: boolean
		}
	}
}

export class CookieBar extends Component {
	protected action = new Action()

	constructor() {
		super()
	}

	private getCookies() {
		return document.querySelector<HTMLElement>('[data-cookies]')
	}

	private getCookiesContent() {
		return document.querySelector<HTMLElement>('[data-cookies-content]')
	}

	private getCookiesSettings() {
		return document.querySelector<HTMLElement>('[data-cookies-settings]')
	}

	private getAllowHandlers() {
		return document.querySelectorAll<HTMLElement>(
			'[data-cookies-allow-handler]',
		)
	}

	private getSettingsHandler() {
		return document.querySelector<HTMLElement>(
			'[data-cookies-settings-handler]',
		)
	}

	private getSelectionHandler() {
		return document.querySelector<HTMLElement>(
			'[data-cookies-selection-handler]',
		)
	}

	private getBasicHandler() {
		return document.querySelector<HTMLElement>('[data-cookies-allow-basic]')
	}

	private getAnalyticCookie() {
		return document.querySelector<HTMLInputElement>('[data-cookies-analytic]')
	}

	private getPreferenceCookie() {
		return document.querySelector<HTMLInputElement>('[data-cookies-preference]')
	}

	private getMarketingCookie() {
		return document.querySelector<HTMLInputElement>('[data-cookies-marketing]')
	}

	private initSettingsHandler() {
		this.getSettingsHandler()?.addEventListener('click', () => {
			this.getCookiesContent()?.setAttribute('style', 'display: none;')
			this.getCookiesSettings()?.removeAttribute('style')
		})
	}

	private initSelectionHandler() {
		this.getSelectionHandler()?.addEventListener('click', async () => {
			this.getCookies()?.setAttribute('style', 'display: none;')
			this.getCookiesContent()?.setAttribute('style', 'display: none;')
			this.getCookiesSettings()?.setAttribute('style', 'display: none;')

			this.action.manual('/track', {
				...window.trackCookies,
				used: true,
			})
		})
	}

	private initBasicHandler() {
		this.getBasicHandler()?.addEventListener('click', () => {
			this.action.manual('/track', {
				allowed: {
					tech: true,
					analytic: false,
					preference: false,
					marketing: false,
				},
				used: true,
			})
		})
	}

	private initAllowHandler() {
		this.getAllowHandlers().forEach(item => {
			item.addEventListener('click', () => {
				const analyticCookie = this.getAnalyticCookie()
				const preferenceCookie = this.getPreferenceCookie()
				const marketingCookie = this.getMarketingCookie()

				if (analyticCookie) {
					analyticCookie.checked = true
				}

				if (preferenceCookie) {
					preferenceCookie.checked = true
				}

				if (marketingCookie) {
					marketingCookie.checked = true
				}

				this.action.manual('/track', {
					allowed: {
						tech: true,
						analytic: true,
						preference: true,
						marketing: true,
					},
					used: true,
				})

				// localStorage.setItem(
				// 	COOKIES_KEY,
				// 	JSON.stringify({
				// 		allowed: {
				// 			tech: true,
				// 			analytic: true,
				// 			preference: true,
				// 			marketing: true,
				// 		},
				// 		used: true,
				// 	}),
				// )
				// this.getCookies()?.setAttribute('style', 'display: none;')
				// window.location.reload()
			})
		})
	}

	private initAnalyticCookies() {
		this.getAnalyticCookie()?.addEventListener('change', e => {
			const t = e.target as HTMLInputElement
			window.trackCookies = {
				...window.trackCookies,
				allowed: {
					...window.trackCookies.allowed,
					analytic: t.checked,
				},
			}
		})
	}

	private initPreferenceCookies() {
		this.getPreferenceCookie()?.addEventListener('change', e => {
			const t = e.target as HTMLInputElement
			window.trackCookies = {
				...window.trackCookies,
				allowed: {
					...window.trackCookies.allowed,
					preference: t.checked,
				},
			}
		})
	}

	private initMarketingCookies() {
		this.getMarketingCookie()?.addEventListener('change', e => {
			const t = e.target as HTMLInputElement
			window.trackCookies = {
				...window.trackCookies,
				allowed: {
					...window.trackCookies.allowed,
					marketing: t.checked,
				},
			}
		})
	}

	init() {
		if (window.trackCookies.used) {
			return
		}

		if (!window.trackCookies.used) {
			this.getCookies()?.removeAttribute('style')
		}

		this.initAnalyticCookies()
		this.initPreferenceCookies()
		this.initMarketingCookies()
		this.initSettingsHandler()
		this.initSelectionHandler()
		this.initBasicHandler()
		this.initAllowHandler()
	}
}
