import { Controller } from '@hotwired/stimulus'

export default class extends Controller<HTMLElement> {
	static targets = [
		'productKey',
		'key',
		'simple',
		'advanced',
		'form',
		'spinner',
		'usesimple',
		'useadvanced',
		'error',
		'more',
		'type',
		'alert',
		'iconClose',
		'iconCheck',
		'simpleTitle',
		'advancedTitle',
		'previousCompany',
	]
	private limit = 25
	declare readonly keyTarget: HTMLFormElement
	declare readonly productKeyTarget: HTMLInputElement
	declare readonly formTarget: HTMLElement
	declare readonly spinnerTarget: HTMLElement
	declare readonly simpleTarget: HTMLFormElement
	declare readonly advancedTarget: HTMLFormElement
	declare readonly usesimpleTarget: HTMLFormElement
	declare readonly useadvancedTarget: HTMLFormElement
	declare readonly errorTarget: HTMLElement
	declare readonly moreTarget: HTMLElement
	declare readonly typeTarget: HTMLInputElement
	declare readonly alertTarget: HTMLInputElement
	declare readonly iconCloseTarget: HTMLElement
	declare readonly iconCheckTarget: HTMLElement
	declare readonly simpleTitleTarget: HTMLElement
	declare readonly advancedTitleTarget: HTMLElement
	declare readonly previousCompanyTarget: HTMLElement

	connect() {
		setTimeout(() => {
			this.handleCloseAlert()
		}, 30000)
		this.handleInitProductKey()
	}

	handleSubmitForm() {
		if (this.spinnerTarget) {
			this.spinnerTarget.classList.remove('is-hidden')
		}
	}

	handleChangeProductKey(e: Event) {
		const t = e.target as HTMLInputElement
		const { value } = t
		let chars = [...value].filter(ch => ch !== '-')
		if (chars.length > this.limit) {
			chars = chars.slice(0, this.limit)
		}
		t.value = this.formatValue(chars)
		if (this.productKeyTarget) {
			this.productKeyTarget.value = t.value
		}
		if (t.value.length === this.limit + 4) {
			this.usesimpleTarget.removeAttribute('data-disabled')
			this.useadvancedTarget.removeAttribute('data-disabled')
			if (!this.errorTarget.classList.contains('hidden')) {
				this.errorTarget.classList.add('hidden')
			}
			this.keyTarget.classList.add('success')
			this.keyTarget.classList.remove('error')
			this.showCheckIcon()
		}
		if (t.value.length !== this.limit + 4) {
			this.usesimpleTarget.setAttribute('data-disabled', '')
			this.useadvancedTarget.setAttribute('data-disabled', '')
		}
	}

	handleBlurProductKey(e: Event) {
		const t = e.target as HTMLInputElement
		if (
			t.value.length === this.limit + 4 &&
			!this.errorTarget.classList.contains('hidden')
		) {
			this.errorTarget.classList.add('hidden')
			this.keyTarget.classList.remove('error')
			this.keyTarget.classList.add('success')
			this.showCheckIcon()
		}
		if (t.value.length !== this.limit + 4) {
			this.errorTarget.classList.remove('hidden')
			this.keyTarget.classList.add('error')
			this.keyTarget.classList.remove('success')
			this.showCloseIcon()
		}
		if (this.productKeyTarget) {
			this.productKeyTarget.value = t.value
		}
	}

	handleChangeUsed(e: Event) {
		console.log(this.previousCompanyTarget)
		if (!this.previousCompanyTarget) {
			return
		}
		const { value } = e.target as HTMLInputElement
		console.log(value.length > 0)
		if (value.length > 0) {
			this.previousCompanyTarget.classList.remove('hidden')
		} else {
			if (!this.previousCompanyTarget.classList.contains('hidden')) {
				this.previousCompanyTarget.classList.add('hidden')
			}
		}
	}

	private handleInitProductKey() {
		if (!this.productKeyTarget) {
			return
		}
		if (this.productKeyTarget.value.length === this.limit + 4) {
			this.errorTarget.classList.add('hidden')
			this.keyTarget.classList.remove('error')
			this.keyTarget.classList.add('success')
			this.showCheckIcon()
		}
	}

	private showCheckIcon() {
		this.iconCheckTarget.classList.remove('hidden')
		if (!this.iconCloseTarget.classList.contains('hidden')) {
			this.iconCloseTarget.classList.add('hidden')
		}
	}

	private showCloseIcon() {
		this.iconCloseTarget.classList.remove('hidden')
		if (!this.iconCheckTarget.classList.contains('hidden')) {
			this.iconCheckTarget.classList.add('hidden')
		}
	}

	private formatValue(chars: string[]) {
		let result: string[] = []
		for (let i = 0, { length } = chars; i < length; i++) {
			result = [...result, chars[i]]
			if ((i + 1) % 5 === 0 && i < this.limit - 1) {
				result = [...result, '-']
			}
		}
		const res = result.join('')
		return res.endsWith('-') ? res.substring(0, res.length - 1) : res
	}

	handleShowSimpleForm() {
		this.formTarget.classList.remove('hidden')
		this.simpleTarget.classList.remove('hidden')
		this.simpleTitleTarget.classList.remove('hidden')
		if (!this.advancedTitleTarget.classList.contains('hidden')) {
			this.advancedTitleTarget.classList.add('hidden')
		}
		if (!this.advancedTarget.classList.contains('hidden')) {
			this.advancedTarget.classList.add('hidden')
		}
		if (this.moreTarget.classList.contains('hidden')) {
			this.moreTarget.classList.remove('hidden')
		}
		this.typeTarget.value = 'simple'
	}

	handleShowAdvancedForm() {
		this.formTarget.classList.remove('hidden')
		this.simpleTarget.classList.remove('hidden')
		this.simpleTitleTarget.classList.add('hidden')
		this.advancedTitleTarget.classList.remove('hidden')
		this.advancedTarget.classList.remove('hidden')
		if (!this.moreTarget.classList.contains('hidden')) {
			this.moreTarget.classList.add('hidden')
		}
		this.typeTarget.value = 'advanced'
	}

	handleShowAdvanced() {
		this.advancedTarget.classList.remove('hidden')
		this.simpleTitleTarget.classList.add('hidden')
		this.advancedTitleTarget.classList.remove('hidden')
		if (!this.moreTarget.classList.contains('hidden')) {
			this.moreTarget.classList.add('hidden')
		}
		this.typeTarget.value = 'advanced'
	}

	handleCloseAlert() {
		if (!this.alertTarget) {
			return
		}
		this.alertTarget.parentNode?.removeChild(this.alertTarget)
		window.history.pushState({}, '', '/')
	}
}
