import { Application } from '@hotwired/stimulus'
import Swiper from 'swiper'
import Lazyload from 'vanilla-lazyload'

import HomeController from './controller/home.controller'
import FormController from './controller/form.controller'
import LangController from './controller/lang.controller'
import ModalController from './controller/modal.controller'
import { CookieBar } from './cookie-bar'
import { Scroller } from './scroller'

declare global {
	interface Window {
		Stimulus: Application
	}
}

window.Stimulus = Application.start()
window.Stimulus.register('home', HomeController)
window.Stimulus.register('form', FormController)
window.Stimulus.register('lang', LangController)
window.Stimulus.register('modal', ModalController)

document.addEventListener('DOMContentLoaded', () => {
	const lazyload = new Lazyload()
	const cookieBar = new CookieBar()
	const scroller = new Scroller()
	cookieBar.init()
	scroller.init()

	const urls = new Swiper('.swiper-urls', {
		slidesPerView: 3,
		spaceBetween: 40,
		loop: true,
		autoplay: {
			delay: 5000,
			disableOnInteraction: true,
		},
		breakpoints: {
			480: {
				slidesPerView: 1,
				spaceBetween: 20,
			},
			640: {
				slidesPerView: 2,
				spaceBetween: 20,
			},
			991: {
				slidesPerView: 3,
				spaceBetween: 30,
			},
		},
	})
})
