import { Component } from './component'

export class Scroller extends Component {
	constructor() {
		super()
	}

	private getScrollAnchors() {
		return Array.from(document.querySelectorAll<HTMLAnchorElement>('a')).filter(a =>
			a.getAttribute('href')?.startsWith('#'),
		)
	}

	private handleSmoothScroll(anchor: HTMLAnchorElement) {
		const id = anchor.getAttribute('href')?.substring(1)
		if (!id) {
			return
		}

		const target = document.getElementById(id)
		if (!target) {
			return
		}

		this.event(anchor, 'click', (e: Event) => {
			e.preventDefault()
			e.stopPropagation()
			target.scrollIntoView({ behavior: 'smooth', block: 'start' })
		})
	}

	private initSmoothScrolls() {
		for (const anchor of this.getScrollAnchors()) {
			this.handleSmoothScroll(anchor)
		}
	}

	init() {
		this.initSmoothScrolls()
	}
}
