import type { Event } from './types'

export abstract class Component {
	protected constructor(private events: Event[] = []) {}

	protected event = (element: any, type: string, listener: any) => {
		this.events = this.events.filter(event => {
			if (event.element === element && event.type === type && String(event.listener) === String(listener)) {
				event.element.removeEventListener(event.type, event.listener)
				return false
			}
			return true
		})

		this.events.push({ element, type, listener })

		element.addEventListener(type, listener)
	}
}
