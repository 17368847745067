import axios from 'axios'
import NProgress from 'nprogress'

import type { Redraw, ActionResponse, ErrorResponse } from './types'
import { Component } from './component'

declare global {
	interface Window {
		langCode: string
	}
}

export class Action extends Component {
	redrawCallback: (() => void) | null = null

	constructor() {
		super()
		NProgress.configure({ showSpinner: false })
	}

	private redraw(redraw: Redraw) {
		const keys = Object.keys(redraw)
		const values = Object.values(redraw)
		for (let r = 0, { length } = keys; r < length; r++) {
			const key = keys[r]
			const value = values[r]
			const parser = new DOMParser()
			const controls = document.querySelectorAll<HTMLElement>(`[id="${key}"]`)
			for (let i = 0, controlLen = controls.length; i < controlLen; i++) {
				const html = parser.parseFromString(value.html, 'text/html')
				const elements = Array.from(html.body.children)
				const control = controls[i]
				if (control) {
					if (value.mode === 'replace') {
						control.parentNode?.replaceChild(html.body.childNodes[0], control)
					}
					if (value.mode === 'append') {
						for (let j = 0, childrenLen = elements.length; j < childrenLen; j++) {
							control.appendChild(elements[j])
						}
					}
					if (value.mode === 'append-multiple') {
						const el = html.body.childNodes[0] as HTMLElement
						for (let j = 0, childrenLen = el.children.length; j < childrenLen; j++) {
							control.appendChild(el.children[j])
						}
					}
					if (value.mode === 'discard') {
						const subcontrol = control.querySelector<HTMLElement>(`[data-gowl-subcontrol="${value.sub}"]`)
						if (subcontrol) {
							subcontrol.parentNode?.removeChild(subcontrol)
						}
					}
				}
			}
		}

		if (this.redrawCallback) {
			this.redrawCallback()
		}
		dispatchEvent(new CustomEvent('on:redraw'))
	}

	private buildChangeUrl(el: HTMLInputElement) {
		let url = el.getAttribute('data-href') || ''
		if (typeof el.getAttribute('data-query') !== 'undefined') {
			return url + window.location.search
		}
		const shouldChecked = el.getAttribute('type') === 'checkbox'
		if (shouldChecked) {
			url += `&checked=${el.checked}`
		} else {
			url += `&value=${el.value}`
		}
		const search = window.location.search.replace('?', '')
		if (search) {
			url += `&${search}`
		}
		return url
	}

	private handleRedirect(path: string) {
		if (path === 'this') {
			window.location.reload()
			return
		}
		window.location.replace(path)
	}

	private getFormJSON(form: HTMLFormElement) {
		if (!form) {
			return {}
		}

		const fields = Array.from(form.querySelectorAll<HTMLElement>('[name]'))
		const result = {} as { [key: string]: any }

		for (let i = 0, { length } = fields; i < length; i++) {
			const field = fields[i]
			const name = field.getAttribute('name')
			if (!name) {
				continue
			}

			const fieldset = field.closest('fieldset')
			if (fieldset && field instanceof HTMLInputElement && field.getAttribute('type') === 'checkbox') {
				const groupName = fieldset.getAttribute('name') || ''
				const hasParent = name.indexOf(':') > -1
				if (hasParent) {
					if (!result[groupName]) {
						result[groupName] = {}
					}

					const parts = name.split(':')
					if (parts.length == 2) {
						const key = parts[0]
						const value = parts[1]
						const isKeyNumber = !isNaN(Number(key)) && key.length > 0
						const isValueNumber = !isNaN(Number(value)) && value.length > 0
						if (!result[groupName][isKeyNumber ? Number(key) : key]) {
							result[groupName] = {
								...result[groupName],
								[isKeyNumber ? Number(key) : key]: [],
							}
						}

						if (field.checked) {
							result[groupName] = {
								...result[groupName],
								[isKeyNumber ? Number(key) : key]: [
									...result[groupName][isKeyNumber ? Number(key) : key],
									isValueNumber ? Number(value) : value,
								],
							}
						}
					}
				}

				if (!hasParent) {
					if (!result[groupName]) {
						result[groupName] = []
					}
					const isNumber = !isNaN(Number(field.value)) && field.value.length > 0
					if (field.checked) {
						result[groupName] = [...result[groupName], isNumber ? Number(field.value) : field.value]
					}
				}

				continue
			}

			if (field instanceof HTMLTextAreaElement) {
				result[name] = field.value
			}
			if (field instanceof HTMLInputElement) {
				const type = field.getAttribute('type')
				if (type === 'checkbox') {
					result[name] = field.checked
				}
				if (type === 'radio' && field.checked) {
					const isNumber = !isNaN(Number(field.value)) && field.value.length > 0
					result[name] = isNumber ? Number(field.value) : field.value
				}
				if (type === 'hidden' && field.getAttribute('data-multiselect-value') !== null) {
					const formObjectName = field.getAttribute('data-form-object')
					if (formObjectName) {
						const objectName = field.name.replace(formObjectName, '').toLowerCase()
						if (!result[formObjectName]) {
							result[formObjectName] = {}
						}
						result[formObjectName][objectName] = field.value
					} else {
						result[name] = field.value
					}
				}
				if (type !== 'checkbox' && type !== 'radio' && type !== 'hidden') {
					const isNumber = !isNaN(Number(field.value)) && field.value.length > 0 && type === 'number'
					result[name] = isNumber ? Number(field.value) : field.value
				}
				if (type === 'hidden' && field.getAttribute('data-multiselect-value') === null) {
					const isNumber = !isNaN(Number(field.value)) && field.value.length > 0
					const isBoolean = !!field.getAttribute('data-equal')
					if (isNumber) {
						result[name] = Number(field.value)
						continue
					}
					if (isBoolean) {
						result[name] = field.getAttribute('data-equal') === field.value
						continue
					}
					result[name] = field.value
				}
			}
		}

		return result
	}

	manual(action: string, data: any) {
		NProgress.start()
		dispatchEvent(new CustomEvent('on:start'))
		return axios
			.post<ActionResponse>(`/${window.langCode}${action}`, data)
			.then(({ data }) => {
				if (data) {
					if (data.redraw) {
						this.redraw(data.redraw)
						this.init()
					}
					if (data.redirect) {
						this.handleRedirect(data.redirect)
					}
				}
				dispatchEvent(new CustomEvent('on:complete'))
				NProgress.done()
			})
			.catch((err: ErrorResponse) => {
				if (err.response?.data) {
					const { redraw } = err.response.data
					if (redraw) {
						this.redraw(redraw)
					}
				}
				dispatchEvent(new CustomEvent('on:complete'))
				NProgress.done()
			})
	}

	private handleForm(el: HTMLFormElement) {
		this.event(el, 'submit', e => {
			NProgress.start()
			dispatchEvent(new CustomEvent('on:start'))
			e.preventDefault()
			axios
				.post<ActionResponse>(el.action, this.getFormJSON(el))
				.then(({ data }) => {
					if (data) {
						if (data.redraw) {
							this.redraw(data.redraw)
							this.init()
						}
						if (data.redirect) {
							this.handleRedirect(data.redirect)
						}
					}
					dispatchEvent(new CustomEvent('on:complete'))
					NProgress.done()
				})
				.catch((err: ErrorResponse) => {
					if (err.response?.data) {
						const { redraw } = err.response.data
						if (redraw) {
							this.redraw(redraw)
						}
					}
					dispatchEvent(new CustomEvent('on:complete'))
					NProgress.done()
				})
		})
	}

	private handleClick(el: HTMLElement) {
		this.event(el, 'click', e => {
			NProgress.start()
			dispatchEvent(new CustomEvent('on:start'))
			e.stopPropagation()
			e.preventDefault()
			axios
				.post<ActionResponse>(el.getAttribute('href') || el.getAttribute('data-href') || '')
				.then(({ data }) => {
					if (data) {
						if (data.redraw) {
							this.redraw(data.redraw)
							this.init()
						}
						if (data.redirect) {
							this.handleRedirect(data.redirect)
						}
					}
					dispatchEvent(new CustomEvent('on:complete'))
					NProgress.done()
				})
				.catch((err: ErrorResponse) => {
					if (err.response?.data) {
						const { redraw } = err.response.data
						if (redraw) {
							this.redraw(redraw)
						}
					}
					dispatchEvent(new CustomEvent('on:complete'))
					NProgress.done()
				})
		})
	}

	private handleChange(el: HTMLInputElement) {
		this.event(el, 'change', e => {
			NProgress.start()
			dispatchEvent(new CustomEvent('on:start'))
			e.preventDefault()
			setTimeout(() => {
				axios
					.post<ActionResponse>(this.buildChangeUrl(el as HTMLInputElement), {
						value: el.value,
					})
					.then(({ data }) => {
						if (data) {
							if (data.redraw) {
								this.redraw(data.redraw)
								this.init()
							}
							if (data.redirect) {
								this.handleRedirect(data.redirect)
							}
						}
						dispatchEvent(new CustomEvent('on:complete'))
						NProgress.done()
					})
					.catch((err: ErrorResponse) => {
						if (err.response?.data) {
							const { redraw } = err.response.data
							if (redraw) {
								this.redraw(redraw)
							}
						}
						dispatchEvent(new CustomEvent('on:complete'))
						NProgress.done()
					})
			}, 250)
		})
	}

	private initClicks() {
		const clicks = document.querySelectorAll<HTMLElement>('a[data-action],button[data-action]')
		for (let i = 0, { length } = clicks; i < length; i++) {
			const click = clicks[i]
			this.handleClick(click)
		}
	}

	private initChanges() {
		const targets = document.querySelectorAll<HTMLInputElement>('[data-action="change"]')
		for (let i = 0, { length } = targets; i < length; i++) {
			const target = targets[i]
			this.handleChange(target)
		}
	}

	private initForms() {
		const forms = document.querySelectorAll<HTMLFormElement>('form')
		for (let i = 0, { length } = forms; i < length; i++) {
			const form = forms[i]
			this.handleForm(form)
		}
	}

	init() {
		this.initForms()
		this.initClicks()
		this.initChanges()

		this.event(window, 'dispatch:redraw', (e: Event) => {
			const event = e as CustomEvent<ActionResponse>
			if (event.detail.redraw) {
				this.redraw(event.detail.redraw)
			}
			if (event.detail.redirect) {
				this.handleRedirect(event.detail.redirect)
			}
		})
	}
}
