import { Controller } from '@hotwired/stimulus'

export default class extends Controller<HTMLElement> {
	static targets = ['select']

	declare readonly selectTarget: HTMLSelectElement

	handleChangeLang(e: Event) {
		const { value } = e.target as HTMLOptionElement
		window.location.replace('/' + value)
	}
}
