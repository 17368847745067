import { Controller, ActionEvent } from '@hotwired/stimulus'

export default class extends Controller<HTMLElement> {
	private readonly MODAL_NAME_PARAM = 'data-modal-name-param'

	static targets = ['modal', 'modal-handler']

	declare readonly modalTargets: HTMLElement[]
	declare readonly modalHandlerTargets: HTMLElement[]

	connect() {
		this.openOnLoad()
	}

	private openOnLoad() {
		if (!window.location.hash.length) {
			return
		}
		const modal = this.findModal(window.location.hash.substring(1))
		if (!modal) {
			return
		}
		if (modal.classList.contains('is-hidden')) {
			modal.classList.remove('is-hidden')
		}
	}

	handleOpen(e: ActionEvent) {
		const modal = this.findModal(e.params.name)
		if (!modal) {
			return
		}
		if (modal.classList.contains('is-hidden')) {
			modal.classList.remove('is-hidden')
			window.location.hash = modal.getAttribute(this.MODAL_NAME_PARAM) || ''
		}
	}

	handleClose(e: ActionEvent) {
		const modal = this.findModal(e.params.name)
		if (!modal) {
			return
		}
		if (!modal.classList.contains('is-hidden')) {
			modal.classList.add('is-hidden')
			window.location.hash = ''
		}
	}

	private findModal(name: string) {
		return Array.from(this.modalTargets).find(
			m => m.getAttribute(this.MODAL_NAME_PARAM) === name,
		)
	}
}
